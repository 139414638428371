
import { defineComponent } from "vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import AuthStore from "@/store/auth-store";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as yup from "yup";
import { ISetting } from "@/types/Setting";
import { IPassword } from "@/types/ChangePassword";
import { notify } from "@/services/helpers";

export default defineComponent({
  Name: "Shifts",
  components: {
    Breadcrumb,
    ConfirmationModal,
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    return {
      crumbs: ["Dashboard", "Settings"],
      roles: AuthStore.getTokenData().Roles,
      api: new WorkForceService(),
      loading: false,
      settingLoading: false,
      passwordLoading: false,
      quotaLoading: false,
      message: [],
      showPassword: false,

      schema2: yup.object().shape({
        currentPassword: yup
          .string()
          .required("Please provide the old password."),
        newPassword: yup
          .string()
          .required("Please provide the new password")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_=+()[\]{}|\\;:'",.<>?/~`])(?=.{8,})/,
            "Your password must meet the below requirements."
          ),
        confirmPassword: yup
          .string()
          .oneOf(
            [yup.ref("newPassword"), null],
            "Password confirmation failed. Please enter the same password in both fields."
          )
          .required("Please confirm the password."),
        leavesQuota: yup.number().required("Please provide the leave quota."),
      }),

      schema3: yup.object().shape({
        leavesQuota: yup
          .number()
          .min(0, "must be greater than 0")
          .max(99, "must not be greater than two digits"),
      }),

      smsRoster: {
        id: 0,
        key: "RosterSMS",
        value: "False",
        description: "Send SMS to notify Employees on Roster Change",
        organisationId: 0,
      },

      emailRoster: {
        id: 0,
        key: "RosterEmail",
        value: "False",
        description: "Send Email to notify Employees on Roster Change",
        organisationId: 0,
      },

      leavesQuotaModel: {
        id: 0,
        key: "LeavesQuota",
        value: "",
        description: "Send Leaves Quota for each Organisation",
        organisationId: 0,
      },

      rosterSetting: "",
      settingMessage: "",
      passwordMessage: "",
      leavesQuota: "",
      newPassword: "",
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      valid_password: false,
    };
  },

  async created(): Promise<void> {
    await this.api.get(`/settings/RosterEmail`, false).then((response: any) => {
      if (response.content.value == "True") {
        this.rosterSetting = response.content.key;
      } else {
        this.api.get(`/settings/RosterSMS`, false).then((response: any) => {
          if (response.content.value == "True") {
            this.rosterSetting = response.content.key;
          }
        });
      }
    });

    await this.api
      .get<ISetting[]>(`/settings/LeavesQuota`, false)
      .then((response: any) => {
        this.leavesQuota = response.content.value;
      });
  },

  methods: {
    async saveRosterSetting() {
      this.settingMessage = "";

      if (this.rosterSetting == "") {
        // show message
        this.settingMessage = "Please Select any Setting";
        return;
      } else {
        if (this.rosterSetting == "RosterEmail") {
          await this.saveEmailRosterSetting("True");
          await this.saveSmsRosterSetting("False");
        } else if (this.rosterSetting == "RosterSMS") {
          await this.saveSmsRosterSetting("True");
          await this.saveEmailRosterSetting("False");
        }
      }
    },

    async saveEmailRosterSetting(value: any) {
      this.settingLoading = true;
      await this.api
        .get<ISetting[]>(`/settings/RosterEmail`, false)
        .then((response: any) => {
          if (response.content.id != 0) {
            this.emailRoster = response.content;
          }
          this.emailRoster.value = value;
        });

      await this.api
        .postOrPut<void>(
          `/settings${
            this.emailRoster.id != 0 ? "/" + this.emailRoster.id : ""
          }`,
          this.emailRoster as ISetting,
          this.emailRoster.id != 0 ? this.emailRoster.id.toString() : undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            notify(
              "Roster notification setting saved successfully.",
              "Success",
              "success"
            );
          } else {
            notify(response.errors, "Error", "danger");
          }
        });
      this.settingLoading = false;
    },

    async saveSmsRosterSetting(value: any) {
      this.settingLoading = true;
      await this.api
        .get<ISetting[]>(`/settings/RosterSMS`, false)
        .then((response: any) => {
          if (response.content.id != 0) {
            this.smsRoster = response.content;
          }
          this.smsRoster.value = value;
        });

      await this.api
        .postOrPut<void>(
          `/settings${this.smsRoster.id != 0 ? "/" + this.smsRoster.id : ""}`,
          this.smsRoster as ISetting,
          this.smsRoster.id != 0 ? this.smsRoster.id.toString() : undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            notify(
              "Roster notification setting saved successfully.",
              "Success",
              "success"
            );
          } else {
            notify(response.errors, "Error", "danger");
          }
        });

      this.settingLoading = false;
    },

    checkPassword() {
      this.password_length = this.newPassword.length;
      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.newPassword);
      this.contains_uppercase = /[A-Z]/.test(this.newPassword);
      this.contains_lowercase = /[a-z]/.test(this.newPassword);
      this.contains_special_character = /^(?=.*[!@#$%^&*\-_=+()[\]{}|\\;:'",.<>?/~`]).+$/.test(
        this.newPassword
      );

      if (
        this.contains_eight_characters &&
        this.contains_special_character &&
        this.contains_uppercase &&
        this.contains_lowercase &&
        this.contains_number
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },

    resetPassFlags() {
      this.newPassword = "";
      this.password_length = 0;
      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_lowercase = false;
      this.contains_special_character = false;
      this.valid_password = false;
    },

    async changePassword(value: any) {
      this.passwordLoading = true;
      await this.api
        .postOrPut<void>(
          `/Account/changePassword`,
          value as IPassword,
          undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            notify(
              "Your password has been changed. Next time please use new password to login.",
              "Success",
              "success"
            );
            (this.$refs["form"] as any).resetForm();
            this.passwordMessage = "";
            this.resetPassFlags();
          } else {
            notify(response.errors, "Error", "danger");
          }
        });

      this.passwordLoading = false;
    },

    async saveLeaveQuota() {
      this.quotaLoading = true;

      await this.api
        .get<ISetting[]>(`/settings/LeavesQuota`, false)
        .then((response: any) => {
          if (response.content.id != 0) {
            this.leavesQuotaModel = response.content;
          }
          this.leavesQuotaModel.value = this.leavesQuota;
        });

      await this.api
        .postOrPut<void>(
          `/settings${
            this.leavesQuotaModel.id != 0 ? "/" + this.leavesQuotaModel.id : ""
          }`,
          this.leavesQuotaModel as ISetting,
          this.leavesQuotaModel.id != 0
            ? this.leavesQuotaModel.id.toString()
            : undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            notify(
              "Leave quota saved/updated successfully.",
              "Success",
              "success"
            );
            return;
          } else {
            notify(response.errors, "Error", "danger");
          }
        });
      this.quotaLoading = false;
    },
  },
});
